::-webkit-scrollbar {
	width: 5px !important;
	height: 7px !important;
	background-color: transparent;
}


/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/

::-webkit-scrollbar-track {
	background-color: #1A4C67;
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}


/*定义滚动条轨道 内阴影+圆角*/

::-webkit-scrollbar-thumb {
	background-color: #0882c9b6;
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.body {
	width: 100%;
	height: 100%;
	background: #0b1924;
	box-sizing: border-box !important;
	padding: 10px;
	overflow: hidden;
}
*,
*::before,
*::after {
	box-sizing: content-box !important;
}

.ant-layout-header {
	height: 64px;
	padding: 0 !important;
	line-height: 64px;
	background: #001529;
}

.ant-menu-inline .ant-menu-submenu-title {
	padding-right: 10px !important;
}

.ant-menu-inline-collapsed {
	width: 90px !important;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
	background: #001529 !important;
	box-shadow: none !important;
}

#components-layout-demo-responsive .logo {
	height: 32px;
	background: rgba(255, 255, 255, 0.2);
	margin: 16px;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item, .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title{
	width: calc(100% - 32px) !important;
}
.ant-calendar-picker-input .ant-input{
	width: calc(100% - 24px) !important;
	height: 30px !important;
	line-height: 30px !important;
}
.ant-calendar-picker{
	height: 30px !important;
	line-height: 30px !important;
}
input .ant-calendar-picker-input .ant-input{
	height: 30px !important;
	line-height: 30px !important;
}
a{text-decoration:none}
.ant-calendar-picker-input.ant-input{
	height: 30px !important;
	line-height: 30px !important;
	padding: 0 11px;
	width: calc(100% - 24px);
}
.ant-breadcrumb>span:first-child a {
	color: #B7B8BC !important;
	font-size: 16px !important;
	line-height: 64px !important;
	margin-left: 20px !important;
}

.ant-breadcrumb>span:last-child a {
	color: #fff !important;
	font-size: 24px !important;
	line-height: 64px !important;
}

.ant-breadcrumb-separator {
	margin: 0 8px !important;
	font-size: 24px !important;
	color: #B7B8BC !important;
}

.ant-breadcrumb a {
	color: #B7B8BC !important;
	font-size: 16px !important;
	line-height: 64px !important;
}

#components-badge-demo-title .ant-badge:not(.ant-badge-status) {
	margin-right: 20px;
}

.ant-layout-content {
	flex: auto;
	background-color: #0b1924;
	overflow-y: auto;
}

.ant-popover-inner {
	background: rgba(48, 48, 60, 0.7) !important;
}

.ant-popover-arrow {
	display: none !important;
}

.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
	padding-top: 0 !important;
}

.ant-popover-inner-content {
	color: #FFF !important;
	padding: 12px !important;
}

.ant-card-head {
	border-bottom: none !important;
}

.ant-card {
	background: transparent !important;
}

.ant-card-body {
	text-align: center !important;
	padding: 0 !important;
	zoom: 1 !important;
}

.ant-drawer-content {
	background: #30303C !important;
}

.ant-drawer-header {
	border-bottom: 1px solid transparent !important;
	background: #30303C !important;
}

.ant-drawer-title {
	color: #FFFFFF !important;
}

.ant-input-number {
	background: transparent !important;
	color: rgba(255, 255, 255, 0.65) !important;
	border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.ant-input-number-handler-wrap {
	background: rgba(255, 255, 255, 0.65) !important;
}

.ant-input-number {
	width: 155px !important;
}

.ant-modal-content {
	background: #30303C !important;
}

.ant-modal-header {
	background: #30303C !important;
	border-bottom: 1px solid rgba(255, 255, 255, 0.12) !important;
}

.ant-modal-footer {
	border-top: 1px solid rgba(255, 255, 255, 0.12) !important;
}

.ant-btn {
	background: transparent !important;
	color: #fff !important;
}

.ant-btn-primary {
	background: #177DDC !important;
	color: #fff !important;
}

.ant-modal-title {
	color: rgba(255, 255, 255, 0.85) !important;
	text-align: center !important;
}
.ant-switch-small{
	border: solid #666 2px !important;
}
.ant-switch-small:after{
	background: #ff0000 !important;
	top: 0 !important;
}
.ant-switch-checked::after{
	background: #00ff00 !important;
	margin-top: 2px !important;
}
.ant-switch::after{
	margin-top: 2px !important;
}
.ant-switch-loading-icon{
	top: 0 !important;
}
.ant-switch-inner .anticon {
	vertical-align: 0em !important;
}
.ant-switch-inner .anticon-close {
	color: #ff0000 !important;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
	color: rgba(255,255,255,0.65) !important;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
	color: rgba(255,255,255,0.65) !important;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled){
	background: #666 !important;
}
.ant-modal-body{
	color: rgba(255,255,255,0.85) !important;
}

.ant-select-dropdown-menu-item:hover{
	background-color: #666 !important;
}
.ant-select-dropdown-menu-item-selected {
    font-weight: 600;
    background-color: #666 !important;
}
.ant-select-dropdown-menu-item{
	color: rgba(255,255,255,0.65) !important;
}
.ant-select-dropdown{
	background-color: #30303C !important;
	box-shadow: 0 2px 8px rgba(255, 255, 255, 1);
}
.ant-select-selection {
    display: block;
    box-sizing: border-box;
    background-color: transparent !important;
    border: 1px solid rgba(255,255,255,0.2) !important;
    border-top-width: 1.02px;
    border-radius: 4px;
    outline: none;
    color: rgba(255,255,255,0.65) !important;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.ant-input[disabled] {
    color: rgba(255,255,255, 0.6) !important;
    background-color: transparent !important;
    border: 1px solid rgba(255,255,255, 0.2) !important;
    cursor: not-allowed;
    opacity: 1;
}
.anticon-question-circle{
	color: #faad14 !important;
}

.ant-tabs{
	color: #B7B8BC !important;
}
.ant-tabs-bar{
	border-bottom: none !important;
}
.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body{
	background:  #23232D !important;
}
.ant-table-tbody > tr > td {
    border-bottom: 1px solid rgba(255,255,255,0.12) !important;
    transition: background 0.3s;
}
.ant-table-thead > tr > th {
    color: rgba(255,255,255,0.85) !important;
    font-weight: 500;
    text-align: left;
    background:  #30303C !important;
    border-bottom: none !important;
    transition: background 0.3s ease;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, 
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, 
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, 
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td{
	background: #666 !important;
}
.ant-table-bordered .ant-table-thead > tr > th, 
.ant-table-bordered .ant-table-tbody > tr > td {
    border-right: none !important;
}
.ant-table-bordered .ant-table-header > table, 
.ant-table-bordered .ant-table-body > table, 
.ant-table-bordered .ant-table-fixed-left table, 
.ant-table-bordered .ant-table-fixed-right table{
	border: none !important;
}
.ant-table-header {
    background: #000 !important;
}
.ant-table-placeholder{
	background:  #23232D !important;
}
.ant-empty-description{
	color: rgba(255,255,255,0.65) !important;
}
.ant-table {
	color: rgba(255,255,255,0.65) !important;
}
.ant-form-item-label>label {
	font-family: "PingFangSC-Regular" !important;
	font-size: 14px !important;
	color: rgba(255, 255, 255, 0.65) !important;
	font-weight: 400 !important;
}

.table-draggable-handle{
	 height: 0 !important;
}
.ant-input{
	width: calc(100% - 24px) !important;
	padding: 0 11px !important;
	background-color: transparent !important;
	border: 1px solid rgba(255,255,255,0.2) !important;
	color: rgba(255, 255, 255, 0.65) !important;
}


.ant-calendar-month-select {
	color: #fff !important;
}

.ant-calendar-year-select {
	color: #fff !important;
}

.ant-calendar-ym-select {
	color: #fff !important;
}

.ant-calendar-date {
	color: rgba(255, 255, 255, 0.65) !important;
}

.ant-calendar-date:hover {
	background-color: rgba(255, 255, 255, 0.65) !important;
}

.ant-calendar-picker-container {
	color: rgba(255, 255, 255, 0.65) !important;
}

.ant-calendar-year-select:hover {
	color: #196bb8 !important;
}

.ant-calendar-month-select:hover {
	color: #196bb8 !important;
}

.ant-calendar-year-panel {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	background: #30303C !important;
	border-radius: 4px;
	outline: none;
}

.ant-calendar-year-panel-decade-select-content {
	color: #fff !important;
}

.ant-calendar-year-panel-decade-select-content:hover {
	color: #196bb8 !important;
}

.ant-calendar-year-panel-year {
	color: rgba(255, 255, 255, 0.65) !important;
}

.ant-calendar-year-panel-year:hover {
	background-color: rgba(255, 255, 255, 0.25) !important;
}

.ant-calendar {
	position: relative;
	width: 280px;
	font-size: 14px;
	line-height: 1.5;
	text-align: left;
	list-style: none;
	background-color: #30303C !important;
	background-clip: padding-box;
	border: none !important;
	border-radius: 4px;
	outline: none;
	box-shadow: 0 2px 8px rgba(255, 255, 255, 1);
}
.ant-calendar-input {
	background-color: #30303C !important;
	color: rgba(255, 255, 255, 0.65) !important;
}
	
.ant-calendar-picker-icon {
	color: rgba(255, 255, 255, 0.65) !important;
}
	
.ant-pagination-disabled a,
.ant-pagination-disabled:hover a,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
	color: rgba(255, 255, 255, 0.65) !important;
	border-color: #d9d9d9;
	cursor: not-allowed;
}
	
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
	display: block;
	height: 100%;
	font-size: 12px;
	text-align: center;
	background-color: transparent !important;
	color: rgba(255, 255, 255, 0.8) !important;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	outline: none;
	transition: all 0.3s;
}
	
.ant-pagination-options-quick-jumper input {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 32px;
	padding: 0 11px !important;
	color: rgba(255, 255, 255, 0.65) !important;
	font-size: 14px;
	line-height: 30px !important;
	background-color: transparent !important;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	transition: all 0.3s;
	width: 50px;
	margin: 0 8px;
}
	
.ant-pagination-item {
	display: inline-block;
	min-width: 32px;
	height: 32px;
	margin-right: 8px;
	font-family: Arial;
	line-height: 30px;
	text-align: center;
	vertical-align: middle;
	list-style: none;
	background-color: transparent !important;
	border: 1px solid #fff;
	border-radius: 4px;
	outline: 0;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ant-pagination-item a {
	display: block;
	padding: 0 6px;
	color: rgba(255, 255, 255, 0.65) !important;
	transition: none;
}

.ant-pagination-item-active {
	background: #196bb8 !important;
}

.ant-pagination-item-active a {
	color: #FFFFFF !important;
}
	
.ant-pagination {
	color: rgba(255, 255, 255, 0.65) !important;
	margin-top: 10px !important;
}

.ant-calendar-month-panel{
	position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background: rgba(48,48,60,1) !important;
    border-radius: 4px;
    outline: none;
}
.ant-calendar-month-panel-year-select-content{
	color: #fff !important;
}
.ant-calendar-month-panel-year-select-content:hover {
    color: #196bb8 !important;
}
.ant-calendar-month-panel-month{
	color: rgba(255,255,255, 0.65) !important;
}
.ant-calendar-month-panel-month:hover {
	background-color: rgba(255,255,255, 0.25) !important;
}

.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month{
	background: rgba(255,255,255, 0.25) !important;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
	width: calc(100% - 46px) !important;
}
	
.ant-pagination-item-ellipsis {
	color: rgba(255, 255, 255, 0.85) !important;
}

.ant-checkbox-inner{
	background: transparent !important;
	border: 1px solid rgba(255, 255, 255, 0.2) !important;
}
.ant-checkbox-checked{
	background: #196bb8 !important;
}
.ant-table-tbody > tr.ant-table-row-selected td{
	background: rgba(90, 90, 90, 0.2) !important;
}
.ant-table-thead > tr > th.ant-table-selection-column, 
.ant-table-tbody > tr > td.ant-table-selection-column {
    text-align: left !important;
}
.ant-form-explain{
	width: 500px!important;
	overflow: hidden;
}
.ant-radio-inner::after {
    top: 4px !important;
    left: 4px !important;
}
.ant-radio-input{
	width: 18px !important;
	height: 18px !important;
}
.ant-radio-inner{
	background-color: transparent !important;
}

.ant-upload-list{
	color: rgba(255, 255, 255, 0.65) !important;
}
.ant-upload-list-item-info .anticon-loading, .ant-upload-list-item-info .anticon-paper-clip{
	color: rgba(255, 255, 255, 0.65) !important;
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: #666 !important; 
}
.ant-upload-list-item-name{
	width: 90% !important; 
}
.ant-upload-list-item-card-actions .anticon{
	color: #ff0000 !important;
}
.ant-input-group-addon{
	background: rgba(255,255,255,0.08) !important;
	border: 1px solid rgba(255,255,255,0.5) !important;
}
.ant-input-password-icon{
	color: rgba(255, 255, 255, 0.65) !important;
}



.ant-pagination-item {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    margin-right: 8px;
    font-family: Arial;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color:  transparent !important;
    border: 1px solid #fff;
    border-radius: 4px;
    outline: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}
.anticon-caret-down{
	color: rgba(255, 255, 255, 0.65) !important;
}
.ant-tree-switcher-icon{
	color: rgba(255, 255, 255, 0.65) !important;
}
.ant-tree li .ant-tree-node-content-wrapper{
	color: rgba(255, 255, 255, 0.65) !important;
}
.ant-tree li .ant-tree-node-content-wrapper:hover{
	background: rgba(255, 255, 255, 0.5) !important;
}
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected{
	background: rgba(255, 255, 255, 0.5) !important;
}
.ant-progress-text{
	color: rgba(255, 255, 255, 0.65) !important;
}
.ant-progress-outer{
	width: 92% !important;
}
.ant-tree-checkbox .ant-tree-checkbox-inner{
	background: transparent !important;
	border: 1px solid rgba(255, 255, 255, 0.2) !important;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner{
	background: #196bb8 !important;
}
.ant-layout-footer {
	/* display: none;
	position: absolute;
	bottom: 0; 
	right: 10px; */
    padding: 10px !important;
    color: rgba(255, 255, 255, 0.65) !important;
    font-size: 14px;
    background: transparent !important;
	pointer-events: none;
}
.ant-layout-footer a{
    color: rgba(255, 255, 255, 0.65);
    font-size: 14px;
    background: transparent !important;
}
.ant-layout-footer a:hover {
    color: rgba(255, 255, 255, 0.8);
}
.record_number {
	z-index: 1000;
	position: absolute;
	bottom:0; 
	right:10px;
    padding: 10px !important;
    color: rgba(255, 255, 255, 0.65);
    font-size: 14px;
    background: transparent !important;
}

.record_number a{
    color: rgba(255, 255, 255, 0.65);
    font-size: 14px;
    background: transparent !important;
}
.record_number a:hover {
    color: rgba(255, 255, 255, 0.8);
}
.ant-form-item-label {
    width: 80px!important;
    overflow: visible;
}

.ant-radio-button-wrapper {
    position: relative;
    display: inline-block;
    height: 32px;
    margin: 0;
    padding: 0 15px;
    color: rgba(255, 255, 255, 0.65);
    line-height: 30px;
    background: transparent; 
    border: 1px solid rgba(255,255,255,0.2);
    border-top-width: 1.02px;
    border-left: 0;
    cursor: pointer;
    transition: color 0.3s, background 0.3s, border-color 0.3s;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #1890ff;
    background: transparent; 
    border-color: #1890ff;
    box-shadow: -1px 0 0 0 #1890ff;
}

.ant-table-bordered.ant-table-empty .ant-table-placeholder {
    border-right: 1px solid rgba(255, 255, 255, 0.12) !important;
    border-left: 1px solid rgba(255, 255, 255, 0.12) !important;
    border-top: 1px solid rgba(255, 255, 255, 0.12) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12) !important;
}

.ant-calendar-cell.ant-calendar-disabled-cell .ant-calendar-date {
	background-color: #434343;
	color: rgb(190 190 190 / 25%);
}
.ant-calendar-cell.ant-calendar-selected-day .ant-calendar-date {
	background-color: #40a9ff;
}